import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, debounce, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridColumnVisibilityModel, GridFeatureMode, GridFetchRowsParams, GridFilterModel, GridPaginationModel, GridRowId, GridSortModel } from "@mui/x-data-grid-pro";
import { useGridApiRef } from "@mui/x-data-grid-pro/hooks/utils/useGridApiRef";
import { GridColDef, GridColumnResizeParams, GridLogicOperator, GridRowHeightParams, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid/models';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { GUID } from 'helpers/GuidFunction';
import { RootState } from 'store/store';
import { DataGridInitialState, DataGridColumns } from "./Index";
import DataGridSlots, { DataGridSlotProps } from "./DataGridSlots";
import { DataGridUtility, resetDataGrid } from './DataGridUtilty';
import { QueryOptions } from './hooks/useQuery';
import storage from 'common/utils/storage';
import { toComputePayload } from './models/IDataGridRequestPayload';
import { ColumnDefinition, IDataGridRequestPayload, IDataGridResponse, IDataGridProps } from "./models/Index";
import { PaginationTypes } from './Enums/PaginationTypes';
import { CustomQueryEditComponent } from './Components/CustomQueryEditComponent';
import { DataGridProps } from './models/DataGridProps';
import { AppFunctions } from 'helpers/AppFunctions';

export type ColumnDefinitionPreferenceType = {
    field: string,
    width: number
}
export type DataGridRefType = {
    getRowModels: () => void;
}

const DataGrid = React.forwardRef<DataGridRefType, IDataGridProps>((props, ref): JSX.Element => {
    const loggedInUserRole = useSelector((state: RootState) => state.login);
    const [orderedColumns, SetOrderedColumns] = useState<string[]>([]);
    const [coldef, SetColDef] = useState<GridColDef>();
    const [initailProps, setInitailProps] = useState<IDataGridProps>(props);
    useEffect(() => { setInitailProps(cloneDeep(props)) }, [])
    const gridColumns = useMemo(() => {
        return DataGridColumns(props, loggedInUserRole!.normalizedRoleName!, orderedColumns, coldef) ?? []
    }, [orderedColumns, coldef]);
    const [initialRows, setInitialRows] = React.useState(props.rows ?? []);
    const [rowCount, setRowCount] = React.useState(props.rows?.length ?? 0);
    const defaultProps = new DataGridProps();
    const [loading, setLoading] = React.useState(false);
    const [gridCheckboxSelection, setGridCheckboxSelection] = React.useState(props.rowSelection ?? defaultProps.rowSelection);
    const apiRef = useGridApiRef();

    const [gridPaginationModel, setGridPaginationModel] = useState<GridPaginationModel>({
        page: 0, pageSize: props.pageSize ?? (props.dataGridServiceType === undefined ? defaultProps.pageSize! : 50)
    })
    const gridFeatureMode: GridFeatureMode = props.dataGridServiceType === undefined ? 'client' : 'server';
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>(props.rowSelectionModel ?? []);

    const columns = (props.columns ?? props.dataGridServiceType?.columns!);


    const disabledColumnsFun = (columns: ColumnDefinition[]) => columns
        .filter(column => column.hideable === false && column.type !== 'actions' && column.cellClassName !== 'cursor-pointer')
        .map(column => column.field);

    const disabledColumns = disabledColumnsFun(columns);

    const getGridUserColumnsFun = () => {
        const localStorageUserPreference = storage.getUserPreference();
        if (!AppFunctions.IsNullOrWhiteSpace(localStorageUserPreference)){
            let userPreferences = JSON.parse(localStorageUserPreference as string);
            let columnPreferences = userPreferences.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.gridColumnPreference;
            if (AppFunctions.IsNullOrUndefined(columnPreferences)) return [];
            return columns.filter(column => !columnPreferences?.split(",")?.includes(column.field))?.map(column => column.field)
        }

        return columns
            .filter(column => column.hideColumnVisibility)
            .map(column => column.field);
    }

    const userGridColumnPreference = getGridUserColumnsFun();

    const hiddenColumnsObject = () => {
        return [...disabledColumns, ...userGridColumnPreference].reduce((idProperty, columns) => ({ ...idProperty, [columns]: false }), {});
    }

    const visibleColumnsFun = (columns: ColumnDefinition[]) => columns
        .filter(column => !column.hideColumnVisibility && !disabledColumnsFun(columns).includes(column.field))
        .map(column => column.field);

    const visibleColumns = visibleColumnsFun(columns);

    const [currentGridColumns] = React.useState<string[]>(visibleColumns);

    const getRowModels = () => {
        const data: Map<GridRowId, GridRowModel> = apiRef.current.getRowModels();
        props.getRowModels?.(Array.from(data?.values()));
    }

    React.useImperativeHandle(ref, () => ({
        getRowModels: () => getRowModels()
    }));

    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>(hiddenColumnsObject());
    
    const getUserPreference = DataGridUtility.getUserPreferenceIfAny(props);
    
    let queryOptions: QueryOptions = {
        page: getUserPreference.page,
        pageSize: getUserPreference.pageSize ?? defaultProps.pageSize!,
        lastRowToRender: props.pageSize ?? defaultProps.pageSize!,
        paginationType: props.lazyLoading ? PaginationTypes.LazyLoading : PaginationTypes.Pagination,
        filterModel: getUserPreference.filterModel,
        sortModel: getUserPreference.sortModel,
        columnDefinitionPreference: getUserPreference.columnDefinitionPreference,
        gridColumnOrderPreference: getUserPreference.gridColumnOrderPreference
    };
    const parsedColumnDefinitionPreference: ColumnDefinitionPreferenceType[] = queryOptions.columnDefinitionPreference ? JSON.parse(queryOptions.columnDefinitionPreference) : [];
    
    const onSortModelChange = React.useCallback(async (sortModel: GridSortModel) => {
        queryOptions.sortModel = [...sortModel];
        queryOptions.columnDefinitionPreference = DataGridUtility.getUserPreferenceIfAny(props).columnDefinitionPreference!;
        queryOptions.gridColumnOrderPreference = DataGridUtility.getUserPreferenceIfAny(props).gridColumnOrderPreference!;
        resetDataGrid(queryOptions, props, defaultProps, setRowCount, setInitialRows);
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
    }, []);

    let hasApplyManualAdditionalDataLoading: boolean = false;

    const onFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        queryOptions.filterModel = filterModel;
        queryOptions.columnDefinitionPreference = DataGridUtility.getUserPreferenceIfAny(props).columnDefinitionPreference!;
        queryOptions.gridColumnOrderPreference = DataGridUtility.getUserPreferenceIfAny(props).gridColumnOrderPreference!;
        resetDataGrid(queryOptions, props, defaultProps, setRowCount, setInitialRows);
        hasApplyManualAdditionalDataLoading = true;
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
        if (props.enableAdditionalLoggingInLocalStorage) {
            localStorage.setItem("additionalFilterLogging", JSON.stringify(filterModel));
        }
    }, []);

    const changeQueryOption = () => {
        queryOptions.filterModel = initailProps.gridFilterInitialState?.filterModel;
        queryOptions.sortModel = initailProps.gridSortingInitialState?.sortModel;
        queryOptions.columnDefinitionPreference = null;
        queryOptions.gridColumnOrderPreference = null;
        DataGridUtility.clearUserPreferenceOnReset();
    }
    
    const onReset = React.useCallback(async () => {
        const columns = (initailProps.columns ?? initailProps.dataGridServiceType?.columns!);
        const visibleColumnlist = visibleColumnsFun(columns);

        const otherFields = columns.map(x => x.field).filter(x => !visibleColumnlist.includes(x));
        const visible = visibleColumnlist.reduce((idProperty, columns) => ({ ...idProperty, [columns]: true }), {});
        const notVisible = otherFields.reduce((idProperty, columns) => ({ ...idProperty, [columns]: false }), {});
        const columnVisibility = { ...visible, ...notVisible };
        apiRef.current.setColumnVisibilityModel(columnVisibility);
        apiRef.current.setSortModel(initailProps.gridSortingInitialState?.sortModel ?? []);
        apiRef.current.setFilterModel(initailProps.gridFilterInitialState?.filterModel ?? {
            items: [],
            logicOperator: GridLogicOperator.And,
            quickFilterValues: [],
        });

        SetOrderedColumns([...[]]);
    }, []);

    const onPaginationModelChange = (model: GridPaginationModel) => {
        (async () => {
            await setGridPaginationModel(model)
            queryOptions.page = model.page;
            queryOptions.pageSize = model.pageSize;
            DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
        })()
    }

    useEffect(() => {
        if (props.rows !== undefined) {
            setGridPaginationModel({ page: queryOptions.page ?? 0, pageSize: queryOptions.pageSize ?? 10 });
            setInitialRows(props.rows!)
            setRowCount(props.rows!.length);
        }
    }, [props.rows])

    useEffect(() => {
        (async () => {
            const { slice, total } = await fetchRow({
                firstRowToRender: 0,
                lastRowToRender: gridPaginationModel.pageSize,
                sortModel: [],
                filterModel: {
                    items: [],
                },
            }, gridPaginationModel, props.objectId);
            if (props.dataGridServiceType === undefined)
                return;
            setInitialRows(slice);
            setRowCount(total);

        })()
    }, [props.dataGridServiceType, gridPaginationModel.page, gridPaginationModel.pageSize, props.objectId]);

    const loadServerRows = async (payload: IDataGridRequestPayload): Promise<IDataGridResponse> => {
        setLoading(true);
        return Promise.resolve(props.dataGridServiceType?.getRows(props.dataGridServiceType.dataUrl, payload))
            .then((data) => {
                setLoading(false);
                return data;
            }, () => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchRow = React.useCallback(
        async (params: GridFetchRowsParams, gridPaginationModel: GridPaginationModel, objectId?: any) => {
            queryOptions.firstRowToRender = params.firstRowToRender;
            queryOptions.lastRowToRender = params.lastRowToRender;
            queryOptions.page = gridPaginationModel.page;
            queryOptions.pageSize = gridPaginationModel.pageSize;
            queryOptions.paginationType = props.lazyLoading ? PaginationTypes.LazyLoading : PaginationTypes.Pagination
            queryOptions.columnDefinitionPreference = DataGridUtility.getUserPreferenceIfAny(props)?.columnDefinitionPreference;
            queryOptions.gridColumnOrderPreference = DataGridUtility.getUserPreferenceIfAny(props)?.gridColumnOrderPreference;
            const payload: IDataGridRequestPayload = toComputePayload(queryOptions);
            if (props.rows)
                return {
                    slice: props.rows,
                    total: props.rows.length,
                };

            payload.objectId = objectId ?? props.objectId;
            const serverRows = await loadServerRows(payload);

            if (gridFeatureMode === 'server' && serverRows && serverRows.totalRowCount !== undefined)
                hasApplyManualAdditionalDataLoading = serverRows.totalRowCount < serverRows.returnedRows?.length;

            return {
                slice: serverRows.returnedRows,
                total: serverRows.totalRowCount,
            };
        },
        [],
    );

    const handleFetchRows = React.useCallback(
        async (params: GridFetchRowsParams) => {
            const isServerSideFilter: boolean = gridFeatureMode === 'server';

            if (hasApplyManualAdditionalDataLoading && isServerSideFilter) {
                params.lastRowToRender = queryOptions.lastRowToRender!;
                params.firstRowToRender = queryOptions.firstRowToRender!;
            }
            //if (params.lastRowToRender < gridPaginationModel.pageSize)
            //    return;

            //TODO: Need to verify the behaviour of firstRowToRender and lastRowToRender
            if (params.firstRowToRender > initialRows.length)
                params.firstRowToRender = initialRows.length;

            const { slice, total } = await fetchRow(params, gridPaginationModel, props.objectId);

            if (!isServerSideFilter || (total > slice?.length && params.firstRowToRender > 0))
                apiRef.current.unstable_replaceRows(params.firstRowToRender, slice);
            else
                setInitialRows(slice)
            setRowCount(total);
        },
        [apiRef, fetchRow, props.objectId],
    );

    const debouncedHandleFetchRows = React.useMemo(
        () => {
            return debounce(handleFetchRows, 600)
        },
        [handleFetchRows],
    );

    const handleRowSelection = (rowIds: GridRowSelectionModel) => {
        if (!props.disableMultipleRowSelection) {
            setSelectionModel(rowIds);
            props.onSelectedRows?.(rowIds as string[]);
            return;
        }
        let newSelectionModel = rowIds;
        if (rowIds.length > 1) {
            const selectionSet = new Set(selectionModel);
            newSelectionModel = newSelectionModel.filter(
                (row) => !selectionSet.has(row)
            );

            setSelectionModel(newSelectionModel);
        }
        setSelectionModel(newSelectionModel);
        props.onSelectedRows?.(newSelectionModel as string[]);

    }


    const processRowUpdate = (updatedRow: any, originalRow: any) => {
        props.processRowUpdate?.(updatedRow, originalRow);
        const data: Map<GridRowId, GridRowModel> = apiRef.current.getRowModels();
        var values = Array.from(data?.values())?.filter(x => x.id !== updatedRow.id);
        props.getRowModels?.([...values, updatedRow]);
        return updatedRow;
    };

    const removeColumns: string[] = [...disabledColumns, "actions"];
    const filteredColumnData: any = [];
    for (const element of gridColumns) {
        if (element) {
            if (removeColumns.includes(element.field)) {
                element.hideable = false;
            }
            filteredColumnData.push(element);
        }
    };

    const gridSlotProps = useMemo(() => DataGridSlots(), []);

    const onColumnResizeHandler = (params: GridColumnResizeParams) => {
        SetColDef(params.colDef);
        onColumnResize(params);
    };

    const onColumnResize = (params: GridColumnResizeParams) => {
        const newColumnPreference = parsedColumnDefinitionPreference?.find(x => x.field == params.colDef.field);
        if (newColumnPreference) {
            newColumnPreference.width = params.colDef.width!;
        }
        else {
            parsedColumnDefinitionPreference.push({
                field: params.colDef.field,
                width: params.colDef.width!
            });
        };

        queryOptions.columnDefinitionPreference = JSON.stringify(parsedColumnDefinitionPreference);
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
        SetColDef(params.colDef);
    };

    const onColumnOrderChangeHandler = (orderdColumns: string[]) => {
        queryOptions.gridColumnOrderPreference = JSON.stringify(orderdColumns);
        SetOrderedColumns(orderdColumns);
        DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
    }


    const debouncedOnColumnResize = React.useMemo(
        () => {
            return debounce(onColumnResize, 600)
        },
        [onColumnResize],
    );

    return (
        <Box className="mui-custom-datagrid" sx={{
            width: "100%"
        }}>
            <div className='table-title-wrapper title-fixed-top-box'>
                <div>
                    <Typography
                        variant="h6"
                        component="h6"
                        sx={{ textAlign: "left", mt: 3, mb: 3, fontSize: "40px", color: "#547283", fontFamily: "Proxima Nova" }}>
                        {props.title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="p"
                        sx={{ textAlign: "left", mb: 2 }}>
                        {props.note}
                    </Typography>
                </div>

                <div className='text-right'>
                    {(DataGridUtility.hasWritePermission(props, loggedInUserRole) || props?.showAdditionalButtons) && props.additionalButtons?.map((button, key) => (
                        <div key={key}>
                            <Tooltip title={button.toolTip}>
                                <span>
                                    <Button
                                        key={button.id ?? GUID.NewGUID()}
                                        startIcon={<AddIcon />}
                                        className={`${button.className ? button.className : 'custom-button'}`}
                                        size="small"
                                        disabled={button.disabled}
                                        variant={button.varient ?? 'text'}
                                        onClick={button.onClick}>{button.label}
                                    </Button>
                                </span>
                            </Tooltip>
                            {button.menuItems}
                        </div>
                    ))}
                </div>
            </div>
            {props.enableCustomQueryEditMode && <CustomQueryEditComponent datagridProps={props} footerOnly={false}></CustomQueryEditComponent>}
            {props.lazyLoading && < DataGridPro
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: props.disableHeaderCheckBox ? "none" : ''
                    }
                }}
                onStateChange={(params: any) => {
                    if (params?.reset) {
                        changeQueryOption();
                        onReset();
                    }
                    if (params?.checkboxSelection) setGridCheckboxSelection(!gridCheckboxSelection);
                }}

                apiRef={apiRef}
                onColumnOrderChange={() => {
                    const orderedFields = apiRef.current.getAllColumns().map(x => x.field);
                    onColumnOrderChangeHandler(orderedFields);
                }}
                sortingOrder={['asc', 'desc']}
                disableMultipleColumnsFiltering={props.disableMultipleColumnsFiltering ?? false}
                rows={initialRows}
                columns={filteredColumnData}

                initialState={DataGridInitialState(props, defaultProps, columnVisibilityModel)}
                onColumnVisibilityModelChange={(newModel) => {
                    setColumnVisibilityModel(newModel);
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                disableColumnMenu
                loading={loading}
                pageSizeOptions={
                    props.pageSizeOptions ?? defaultProps.pageSizeOptions
                }
                checkboxSelection={gridCheckboxSelection}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(rowIds) => {
                    handleRowSelection(rowIds);
                }}
                onCellClick={(currentRow, event) => {
                    if (event.ctrlKey) return;
                    props.onCellClick?.(currentRow, currentGridColumns)
                }}
                onRowOrderChange={(gridRowOrderChangeParams) => {
                    props.onRowOrderChange?.(gridRowOrderChangeParams as any);
                }}
                isRowSelectable={props.isRowSelectable}
                disableRowSelectionOnClick={props.disableRowSelectionOnClick}

                pagination={props.lazyLoading ? false : (props.pagination ?? defaultProps.pagination)}
                onPaginationModelChange={onPaginationModelChange}
                paginationMode={gridFeatureMode}
                slots={gridSlotProps}
                slotProps={DataGridSlotProps(props)}
                disableDensitySelector={props.disableDensitySelector ?? defaultProps.disableDensitySelector}
                disableColumnSelector={
                    props.disableColumnSelector ?? defaultProps.disableColumnSelector
                }
                disableColumnFilter={
                    props.disableColumnFilter ?? defaultProps.disableColumnFilter
                }
                disableColumnPinning={props.disableColumnPinning ?? defaultProps.disableColumnPinning}
                hideFooter={props.hideFooter ?? defaultProps.hideFooter}
                showCellVerticalBorder={
                    props.showCellVerticalBorder ??
                    defaultProps.showCellVerticalBorder
                }
                showColumnVerticalBorder={
                    props.showColumnVerticalBorder ??
                    defaultProps.showColumnVerticalBorder
                }
                rowCount={rowCount}
                rowReordering={props.rowReordering ?? defaultProps.rowReordering}
                rowsLoadingMode={gridFeatureMode}
                sortingMode={gridFeatureMode}
                onSortModelChange={onSortModelChange}
                filterMode={gridFeatureMode}
                hideFooterRowCount={true}
                onFilterModelChange={onFilterModelChange}
                rowHeight={33}
                onFetchRows={debouncedHandleFetchRows}
                experimentalFeatures={{
                    lazyLoading: props.lazyLoading,
                    columnGrouping: props.isColumnGrouping
                }}
                columnGroupingModel={props.columnGroupingModel}
                onColumnResize={onColumnResizeHandler}
                onColumnWidthChange={debouncedOnColumnResize}
            />}

            {!props.lazyLoading && < DataGridPro
                sortingOrder={['asc', 'desc']}
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: props.disableHeaderCheckBox ? "none" : ''
                    }
                }}
                getRowClassName={(params: any) => { return props.getRowClassName?.(params) }}
                apiRef={apiRef}
                onStateChange={(params: any) => {
                    if (params?.reset) {
                        changeQueryOption();
                        onReset();
                    }
                    if (params?.checkboxSelection) setGridCheckboxSelection(!gridCheckboxSelection);
                }}
                disableMultipleColumnsFiltering={props.disableMultipleColumnsFiltering ?? false}
                rows={initialRows}
                columns={filteredColumnData}
                initialState={DataGridInitialState(props, defaultProps, columnVisibilityModel)}
                onColumnVisibilityModelChange={(newModel) => {
                    setColumnVisibilityModel(newModel);
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                loading={loading}
                disableColumnMenu
                pageSizeOptions={
                    props.pageSizeOptions ?? defaultProps.pageSizeOptions
                }
                checkboxSelection={gridCheckboxSelection}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(rowIds) => {
                    handleRowSelection(rowIds);
                }}
                onCellClick={(currentRow, event) => {
                    if (event.ctrlKey) return;
                    props.onCellClick?.(currentRow, currentGridColumns)
                }}
                onRowOrderChange={(gridRowOrderChangeParams) => {
                    props.onRowOrderChange?.(gridRowOrderChangeParams as any);
                }}
                isRowSelectable={props.isRowSelectable}
                disableRowSelectionOnClick={props.disableRowSelectionOnClick}

                pagination={props.pagination ?? defaultProps.pagination}
                onPaginationModelChange={onPaginationModelChange}
                onFilterModelChange={(filterModel: GridFilterModel) => {
                    queryOptions.filterModel = filterModel;
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                onSortModelChange={(sortModel: GridSortModel) => {
                    queryOptions.sortModel = [...sortModel];
                    DataGridUtility.addUserPreferencesAsync(props.moduleTitle!, queryOptions, apiRef);
                }}
                onColumnOrderChange={() => {
                    const orderedFields = apiRef.current.getAllColumns().map(x => x.field);
                    onColumnOrderChangeHandler(orderedFields);
                }}
                paginationMode={gridFeatureMode}
                slots={DataGridSlots()}
                slotProps={DataGridSlotProps(props)}
                disableDensitySelector={props.disableDensitySelector ?? defaultProps.disableDensitySelector}
                disableColumnSelector={
                    props.disableColumnSelector ?? defaultProps.disableColumnSelector
                }
                disableColumnFilter={
                    props.disableColumnFilter ?? defaultProps.disableColumnFilter
                }
                disableColumnPinning={props.disableColumnPinning ?? defaultProps.disableColumnPinning}
                hideFooter={props.hideFooter ?? defaultProps.hideFooter}
                showCellVerticalBorder={
                    props.showCellVerticalBorder ??
                    defaultProps.showCellVerticalBorder
                }
                showColumnVerticalBorder={
                    props.showColumnVerticalBorder ??
                    defaultProps.showColumnVerticalBorder
                }
                rowCount={rowCount}
                autoHeight={true}
                getRowHeight={(params: GridRowHeightParams) => props.rowHeightAuto ? 'auto' : props.rowHeight ?? defaultProps.rowHeight}
                rowReordering={props.rowReordering ?? defaultProps.rowReordering}
                editMode={props.editMode}
                processRowUpdate={processRowUpdate}
                columnGroupingModel={props.columnGroupingModel}
                experimentalFeatures={{ columnGrouping: props.isColumnGrouping }}
                onColumnResize={onColumnResizeHandler}
                onColumnWidthChange={debouncedOnColumnResize}
            />}
            {props.enableCustomQueryEditMode && <CustomQueryEditComponent datagridProps={props} footerOnly={true}></CustomQueryEditComponent>}

        </Box>

    );
});

export default DataGrid;